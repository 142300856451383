import React, { useContext } from "react";
import { Route as RouterRoute, Redirect } from "react-router-dom";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";

const Route = ({ component: Component, isPrivate = false, requiredRoles = [], ...rest }) => {
  const { isAuth, loading, user } = useContext(AuthContext);

  if (loading) {
    return <BackdropLoading />;
  }

  if (!isAuth && isPrivate) {
    return <Redirect to={{ pathname: "/login", state: { from: rest.location } }} />;
  }

  if (isAuth && requiredRoles.length > 0 && (!user || !requiredRoles.includes(user.profile))) {
    return <Redirect to={{ pathname: "/", state: { from: rest.location } }} />;
  }

  return <RouterRoute {...rest} render={props => <Component {...props} />} />;
};

export default Route;
